/**
 * This middleware is used to strip the locale from the URL path.
 *
 * This was introduced as part of the task to store the locale in the user config and not in the URL and cookies anymore.
 * However, the locale may still be part of the URL if users stored bookmarks with the locale in it.
 * To not break these bookmarks, we need to strip the locale from the URL path.
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const localePattern = /^\/(en|de|da|es|fr|it|nl|pt|sl|pl|fi|sv|et)(\/|$)/ 
  const match = to.path.match(localePattern)

  if (match) {
    const newPath = to.path.replace(localePattern, '/')
    return navigateTo({ ...to, path: newPath })
  }
})