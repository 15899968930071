
export default defineNuxtPlugin(nuxtApp => {
  const translate = (type, lang, json, nested = false) => {
    if (json && Array.isArray(json)) {
      const found = json.find(obj => obj.code == lang)
      if (found && found.translations) {
        let content
        if (nested) {
          content = findProp(found.translations, type)
        } else {
          content = found.translations[type]
        }
        if (content) {
          return content
        }
        return type
      }
      return type
    }
    return type
  }

  nuxtApp.provide('translate', translate)
})

function findProp(obj, prop, defval) {
  if (typeof defval == 'undefined') defval = null
  prop = prop.split('.')
  for (var i = 0; i < prop.length; i++) {
    if (typeof obj[prop[i]] == 'undefined') return defval
    obj = obj[prop[i]]
  }
  return obj
}