<script setup>
window.cloneObj = obj => {
  return JSON.parse(JSON.stringify(obj))
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
