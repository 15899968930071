
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de",
  "da",
  "es",
  "fr",
  "it",
  "nl",
  "pt",
  "pl",
  "sl",
  "sv",
  "fi",
  "et"
]

export const localeLoaders = {
  "en": [],
  "de": [],
  "da": [],
  "es": [],
  "fr": [],
  "it": [],
  "nl": [],
  "pt": [],
  "pl": [],
  "sl": [],
  "sv": [],
  "fi": [],
  "et": []
}

export const vueI18nConfigs = [
  () => import("../nuxt-i18n.js?hash=82975b67&config=1" /* webpackChunkName: "__nuxt_i18n_js_82975b67" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./nuxt-i18n.js",
  "locales": [
    {
      "code": "en",
      "iso": "en",
      "name": "English",
      "label": "EN"
    },
    {
      "code": "de",
      "iso": "de",
      "name": "German",
      "label": "DE"
    },
    {
      "code": "da",
      "iso": "da",
      "name": "Danish",
      "label": "DA"
    },
    {
      "code": "es",
      "iso": "es",
      "name": "Spanish",
      "label": "ES"
    },
    {
      "code": "fr",
      "iso": "fr",
      "name": "French",
      "label": "FR"
    },
    {
      "code": "it",
      "iso": "it",
      "name": "Italian",
      "label": "IT"
    },
    {
      "code": "nl",
      "iso": "nl",
      "name": "Dutch",
      "label": "NL"
    },
    {
      "code": "pt",
      "iso": "pt",
      "name": "Portuguese",
      "label": "PT"
    },
    {
      "code": "pl",
      "iso": "pl",
      "name": "Polish",
      "label": "PL"
    },
    {
      "code": "sl",
      "iso": "sl",
      "name": "Slovenian",
      "label": "SL"
    },
    {
      "code": "sv",
      "iso": "sv",
      "name": "Swedish",
      "label": "SV"
    },
    {
      "code": "fi",
      "iso": "fi",
      "name": "Finnish",
      "label": "FI"
    },
    {
      "code": "et",
      "iso": "et",
      "name": "Eesti",
      "label": "ET"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en",
    "name": "English",
    "label": "EN",
    "files": []
  },
  {
    "code": "de",
    "iso": "de",
    "name": "German",
    "label": "DE",
    "files": []
  },
  {
    "code": "da",
    "iso": "da",
    "name": "Danish",
    "label": "DA",
    "files": []
  },
  {
    "code": "es",
    "iso": "es",
    "name": "Spanish",
    "label": "ES",
    "files": []
  },
  {
    "code": "fr",
    "iso": "fr",
    "name": "French",
    "label": "FR",
    "files": []
  },
  {
    "code": "it",
    "iso": "it",
    "name": "Italian",
    "label": "IT",
    "files": []
  },
  {
    "code": "nl",
    "iso": "nl",
    "name": "Dutch",
    "label": "NL",
    "files": []
  },
  {
    "code": "pt",
    "iso": "pt",
    "name": "Portuguese",
    "label": "PT",
    "files": []
  },
  {
    "code": "pl",
    "iso": "pl",
    "name": "Polish",
    "label": "PL",
    "files": []
  },
  {
    "code": "sl",
    "iso": "sl",
    "name": "Slovenian",
    "label": "SL",
    "files": []
  },
  {
    "code": "sv",
    "iso": "sv",
    "name": "Swedish",
    "label": "SV",
    "files": []
  },
  {
    "code": "fi",
    "iso": "fi",
    "name": "Finnish",
    "label": "FI",
    "files": []
  },
  {
    "code": "et",
    "iso": "et",
    "name": "Eesti",
    "label": "ET",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


