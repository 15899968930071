export default defineNuxtPlugin((nuxtApp) => {
  const detectOS = () => {
    const userAgent = window.navigator.userAgent
    return {
      windows: userAgent.includes('Win'),
      mac: userAgent.includes('Mac'),
      linux: userAgent.includes('Linux'),
    }
  }
  nuxtApp.provide('os', detectOS)
})
