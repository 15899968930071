import * as Sentry from '@sentry/vue'

//https://www.lichter.io/articles/nuxt3-sentry-recipe/
export default defineNuxtPlugin(nuxtApp => {
  const {
    public: { sentry },
  } = useRuntimeConfig()

  const config = useRuntimeConfig()

  const isApp = config.public.name === 'app'
  if (!sentry.dsn) {
    return
  }

  // Set sample rates based on environment
  let replaysSessionSampleRate = 0.0
  let replaysOnErrorSampleRate = 0.0

  if(isApp){
    replaysOnErrorSampleRate = 0.1
  }


  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  })

  nuxtApp.provide('sentry', Sentry)
})
