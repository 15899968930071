<template>
  <div class="h-screen w-screen bg-primary flex items-center">
    <div
      class="container flex flex-col md:flex-row items-center justify-center px-5 text-white"
    >
      <div class="max-w-md">
        <div class="text-5xl font-dark font-bold">{{ error.statusCode }}</div>
        <p>{{ error.statusMessage }}</p>
        <p
          v-if="error.statusCode === 404"
          class="text-2xl md:text-3xl font-light leading-normal"
        >
          {{ $t("Sorry we couldn't find this page.") }}
        </p>
        <p v-else class="text-2xl md:text-3xl font-light leading-normal">
          {{ $t('Oops! Something went wrong.') }}
        </p>
        <p
          class="mb-8"
          v-if="serverErrorCodes.some(e => e === error.statusCode)"
        >
          {{ $t('Please come back at a later time.') }}
        </p>
        <p class="mb-8" v-else>
          {{
            $t(
              "But don't worry, you can find plenty of other things on our homepage."
            )
          }}
        </p>

        <button
          v-if="serverErrorCodes.some(e => e === error.statusCode)"
          @click="statusPage"
          class="px-4 inline py-2 text-sm font-medium leading-5 shadow text-primary bg-secondary transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-600 hover:bg-blue-700"
        >
          {{ $t('Check our status page for updates') + '.' }}
        </button>
        <button
          v-else
          @click="goBack"
          class="px-4 inline py-2 text-sm font-medium leading-5 shadow text-primary bg-secondary transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-600 hover:bg-blue-700"
        >
          {{ $t('Back to homepage') }}
        </button>
      </div>
      <div class="max-w-lg">
        <div class="svg-container w-14 h-14 m-2">
          <svg
            version="1.1"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1920 1920"
            style="enable-background: new 0 0 1920 1920"
            xml:space="preserve"
            width="300px"
            height="300px"
          >
            <!-- SVG code from Illustrator export -->
            <g id="wolpi-flap">
              <path
                class="st0"
                d="M1197,1113.2c0.5-0.7,1-1.4,1.3-2.2c3.6-8-3.7-17.4-12.8-14.8c-18,5.1-40.7,10.5-56.1,10.5
      c-15,0-29.8-2.2-43.4-7.5c-4.2-1.4-7.9-3.9-10.7-7.2c-3.9-4.6-5.8-10.2-5.8-15.9c0-0.9,0-1.7,0.2-2.5c0.1-0.8,0.2-1.6,0.4-2.4
      c0.2-0.7,0.4-1.5,0.6-2.2c0-0.1,0-0.2,0.1-0.3c0.1-0.4,0.3-0.9,0.5-1.4c0.1-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.4-0.8,0.7-1.3
      c0.2-0.3,0.3-0.7,0.6-1c0.2-0.4,0.5-0.8,0.9-1.3c0.1-0.3,0.3-0.6,0.6-0.9c0.2-0.4,0.5-0.7,0.9-1.1c0.2-0.3,0.5-0.6,0.9-1
      c0.3-0.4,0.7-0.8,1.2-1.1c0.2-0.2,0.3-0.4,0.5-0.5c0.3-0.2,0.6-0.5,0.9-0.7c0.5-0.4,1-0.8,1.5-1.3c-0.6,0.1-1.3,0.2-1.9,0.4
      c-0.5,0.1-1.1,0.2-1.6,0.2c-1.6,0.3-3.1,0.5-4.8,0.8c-2.4,0.4-4.8,0.7-7.2,1c-1.8,0.2-3.7,0.5-5.6,0.7c-4,0.4-8.1,0.8-12.1,1.1
      c-2.2,0.2-4.4,0.3-6.7,0.4c-1.6,0-3.3,0.1-4.9,0.1c-2.8,0.1-5.6,0.2-8.4,0.2c-9.2,0-18.4-0.4-27.6-1.3
      c-43.8-4.5-96.1-22.4-120.5-61.7l-1.3-2.2c-0.4-0.6-0.8-1.3-1.2-2c-1.1-1.9-1.9-3.9-2.5-6c-3.5-12.8,4.2-26.2,17.1-29.7
      c0.6-0.2,1.2-0.3,1.8-0.5c-3.9-1-7.8-2.1-11.7-3.3c-24.8-7.7-46.6-19.7-63.7-36.5c1.2-0.4,2.3-0.8,3.5-1.1c0.7-0.2-0.3-1.6-0.6-1.5
      c-14.6-14.2-25.8-31.9-32.8-53.3l-0.4-1.6l-0.4-2c-0.4-1.2-0.4-2-0.4-3.3c0-7.3,3.3-13.8,8.1-18.3c-2-0.8-3.7-1.6-5.7-2.4
      c-36.2-16.3-62.3-44.4-70-84.3l-0.4-1.6v-2c0-8.6,4.1-16.3,11-20.8c-4.9-2.4-9.8-5.3-14.3-8.2c-35.4-22.4-52.5-55-52.5-96.1
      c-0.4-2.9,0-6.1,1.2-9c2-6.1,6.9-11,12.6-13.4c-2.8-2.4-5.3-4.5-7.7-6.9c-34.2-32.2-47.6-72.9-44.4-118.9v-1.6l0.4-2
      c0.8-2.9,1.6-5.3,3.3-7.8c2.9-4.1,6.5-7.3,11-9c-27.7-38.3-40.3-83.9-40.3-131.5c-0.4-6.1,2-12.2,6.1-16.7
      c6.5-7.3,16.3-9.8,24.8-6.9c-3.3-8.5-5.7-17.5-7.7-26.9c-6.5-30.9-5.7-61.9,4.1-92c1.6-4.9,4.5-9,8.6-11.8
      c10.6-7.7,25.7-5.3,33.4,5.3c20.4,27.7,43.2,53.3,68,77c22.8,21.6,46.8,41.9,72.1,61.1c9.4-2.4,19.5,0.8,25.7,9
      c19.1,26.5,43.2,48.9,69.2,68c22.4,16.7,46.8,30.5,72.1,42.8c0.8-2,2.4-4.1,4.1-5.7c9.4-9.8,24.4-9.8,33.8-0.8
      c25.3,24,53.8,44.4,84.7,60.3c31.8,16.3,66,28.1,101.4,35.4c19.5,4.1,40.7,11,56.6,23.6c15.9,12.6,24.8,30.6,22,50.9
      c-5.7,38.3-4.1,77.4,11.4,113.6c4.5,11,24,38.7,20.8,35.8c11.8,13,73.2,73.1,99.3,106.5C1413.6,1034.6,1197,1113.2,1197,1113.2z"
              />
            </g>
            <g>
              <path
                class="st0"
                d="M1680.3,1081.8L1680.3,1081.8c-9.1,20-19.4,40-30.4,59.3c-3.8,6.7-2.3,15.2,3.5,20.3
          c21.6,18.8,43.6,41.6,60.2,66.6c3.6,5.4,10.1,8.2,16.5,7c10.2-1.9,20.5-3.9,30.6-6.3c9.7-2.3,19.1,4.8,19.8,14.7l4.2,58.3
          c0.7,10.1,10.3,16.9,20.2,14.7c85.9-19.8,123.8-124.5,68.7-204C1845.7,1072,1747.5,1078.9,1680.3,1081.8z"
              />
              <path
                class="st0"
                d="M1873.4,929.1c10.6,20,7.7,56.6-0.4,69.2c-5.7,9-13.9,14.7-20,4.9c-3.7-6.1-0.4-21.6-2.9-28.9
          c-2-5.7-4.9-10.6-8.1-15.5c-3.3,2.4-6.1,4.9-9.4,6.9c-1.2,22-19.1,50.5-31.4,57.8c-9,5.3-19.1,6.5-20-4.9
          c-0.4-6.5,9.4-19.1,10.6-26.9c0.4-2.9,0.4-5.7,0.4-8.6c-23.7,4.7-51.6,1.8-86.3-12.9c-1.8-0.7-3.7,0.2-4.3,2
          c-21.6,68.6-49.2,133.8-85.4,191.3c-0.9,1.4-0.6,3.2,0.7,4.2c53.3,40.6,102.8,98.4,103.6,151.4c0.4,22-5.7,44.4-15.5,63.9
          c-43.7,87-136.3,88-192.8,51.8c-1.5-1-1.9-2.9-1-4.4l54.2-86.2c1.1-1.7,0.4-3.9-1.5-4.6c-24.7-10.5-49.5-22.6-74.2-35.6
          c-1.1-0.5-2.4-0.4-3.3,0.2c-236.5,169.3-570.1,43.8-699.3,108c-104.8,90.6-251.8,147.1-406.9,122.4c-0.7-0.1-1.5,0-2.1,0.4
          l-171.5,103.4c-0.6,0.4-1.1,1-1.4,1.8C173,1746.4,97.8,1769.8,2.7,1753.6c-2-0.4-3.2-2.5-2.5-4.4l79.2-201.8
          c0.3-0.6,0.7-1.2,1.3-1.6l280.1-174.3c0.2-0.1,0.5-0.2,0.8-0.3c60.1-17.2,111.7-61.9,148.7-116.4c12.3-19.8,1.9-40.8-9.9-55.6
          c-9.6-12.1-23.7-19.8-39-21.6c-42.5-4.9-140.5-24.9-125.8-103.6c0.3-1.7,2-2.9,3.7-2.6c46.1,7.5,86.2,14.2,133.1-0.7
          c58.7-18.8,177.4-88.4,291.7-134c13.9,17.1,32.2,33.7,51.3,46c0.4,2.3,1,4.6,1.6,6.9c2.3,8.5,5.7,16.7,10.1,24.5
          c0.8,1.4,1.5,2.5,1.9,3.2l0.1,0.3l0.2,0.3l1.3,2.1l0.3,0.6l0.4,0.5c48.1,77.2,141.6,94.6,179.5,98.5c6.8,0.7,13.6,1.2,20.4,1.5
          c0.9,1.3,2,2.6,3.1,3.8c1.8,2,3.9,4.1,6.4,6.3c20.4,17.4,47.6,24.9,74.2,21.8c25.2-3,56.4-9.9,70.8-26.2c4.1-4.6,7.9-9.4,11.3-13.8
          c0,0,183.7-113.8,179.1-299.4c17.5-3.7,35-8.2,52.5-13c10.2-11,16.7-20.8,20.4-26.9c2.4-23.2,9.8-44.4,21.2-61.5
          c-155.1-96.2-204.2-251.5-199.1-424.6c0.3-10.4,10.1-17.7,20.2-15.4c237.9,55.1,284.5,338.1,322.9,359.9
          c29.1-13.2,50.7-8.3,53.1-8.3c5.3-13.8,11-28.1,15.1-42.8c3.7-11.4,5.7-23.2,5.7-35c0.4-42.4-11.8-81.9-24.4-121.8
          c-7.8-25.3-19.1-48.5-37.5-67.2c-14.3-14.2-30.6-26.1-46.8-37.9c-24.4-17.5-30.5-31.8-22.4-57c1.2-4.1,4.1-7.3,6.9-10.2
          c1.6-1.2,5.7-2,7.3-0.8c1.2,0.8,2.4,4.9,2,6.9c-4.5,15.9-2.8,20.8,13.9,33.4c9.8,7.7,21.2,12.2,32.2,18.3c3.7,2,6.9,4.1,11.4,6.5
          c-2-6.5-4.1-11.8-4.9-17.5c-5.7-31.4,1.6-59.5,21.2-84.3c3.7-4.5,6.1-9.4,4.5-15.9c-0.8-2.4,1.2-6.9,3.3-7.7c2.8-0.8,7.7,0,9,2
          c1.6,3.7,3.3,8.6,2,12.2c-2.9,10.2-7.7,20-11,30.1c-7.8,26.1-6.9,52.1-0.4,77.8c4.1,16.3,9.4,33,17.9,47.3
          c13,20.8,23.6,41.9,30.1,65.6c0.8,2.4,3.7,4.5,5.3,6.9c1.6-2.4,4.1-4.9,4.9-7.3c7.3-27.3,19.5-52.1,35.8-74.9
          c9-12.2,18.7-24,27.3-36.2c2.9-4.1,2.9-10.2,4.5-15.1c0.8-3.3,2-7.7,4.1-8.1c2.9-0.4,7.3,1.2,9.4,3.3c5.3,5.7,4.5,15.5,0,22.4
          c-7.7,12.2-16.3,24-22.4,37.1c-27.3,58.2-38.3,118.9-28.1,182.5c2.4,15.5,6.1,30.1-1.2,45.2c-0.4,0.4-0.4,0.8-0.4,0.8
          c0.8,1.6,1.2,3.7,2,5.3c1.6-0.8,4.1-1.2,4.9-2.4c1.6-2.4,2.4-5.3,3.7-8.1c4.5-9.8,7.7-20,13.4-28.5c9.8-14.7,24.4-24.4,39.1-33.8
          c19.5-12.2,40.7-20.8,63.5-25.3c4.1-0.8,6.9,0.4,7.3,4.1c0,2.4-2,6.1-4.1,7.3c-11.8,8.1-25.3,14.7-36.7,23.6
          c-32.2,24.8-52.9,58.2-61.1,98.1c-2.9,14.2-3.7,28.5-0.8,43.6c2.4-2,4.1-3.3,5.7-4.9c26.9-25.7,57.4-43.6,94.1-50.9
          c11-2,22-2.4,33.4-2.4c2.4,0,5.3,3.7,8.1,5.7c-2.4,2-4.5,4.9-7.3,5.3c-13.8,2-26.5,7.8-38.7,13.9c-28.1,13.8-52.5,31.8-68,59.9
          c-0.4,0.4-0.8,0.8-0.8,1.2c3.3,4.1,6.5,8.6,9.8,13l108,154.6c4.2,6.1,3.8,14.3-1,19.9C1901.3,899,1887.5,915.6,1873.4,929.1z"
              />
            </g>
            <line class="st1" x1="911.8" y1="776.1" x2="910" y2="776.8" />
            <path
              class="st0"
              d="M1125.4,1064.8c-0.2,0.5-0.5,1-0.9,1.5L1125.4,1064.8z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serverErrorCodes: [500, 502, 503, 504],
    }
  },
  props: {
    error: Object,
  },
  methods: {
    statusPage() {
      window.location.replace('https://status.transkribus.org/')
    },
    goBack() {
      window.location.replace('/')
    },
  },
}
</script>

<style scoped>
.st0 {
  fill: white;
}
.st1 {
  fill: none;
}

@keyframes rotateFlap {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#wolpi-flap {
  animation: rotateFlap 1.5s infinite;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform-origin: center center;
}
</style>
