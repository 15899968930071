import mitt from 'mitt'

const emitter = mitt()

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('bus', {
    emit: emitter.emit, // Will emit an event
    on: emitter.on, // Will register a listener for an event
    off: emitter.off, // Will unregister a listener for an event
  })
})

// to use the Eventbus just import by like this:
// const { $bus } = useNuxtApp();
// $bus.emit("my-event", "Hello World");

// when using the Options API, just use:
// this.$bus
