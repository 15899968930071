import { defineStore } from 'pinia'
import Keycloak from 'keycloak-js'
interface UserProfile {
  IsEmailVerified: boolean
  Name: string
  UserName: string
  FirstName: string
  LastName: string
  Email: string
  UserId: number
  IsAdmin: boolean
  Scholar: boolean
  Era: boolean
  Epoch: boolean
  Eon: boolean
  Team: boolean
  Individual: boolean
  FieldsRecognition: boolean
  SuperModels: boolean
  NER: boolean
  ViewAllModels: boolean
  UpdateAllModels: boolean
  SmartSearch: boolean
  TablesRecognition: boolean
  CollaborationTools: boolean
  Export: boolean
}

export const useKeycloak = defineStore('keycloak', () => {
  const keycloak = ref<Keycloak>()
  let userProfile = ref<UserProfile>()
  let roles = ref<string[]>([])
  let loggedIn = ref<boolean>(false)

  function hasRole(role: string) {
    return roles.value.includes(role)
  }

  function setLogined(isLogin: boolean) {
    loggedIn.value = isLogin
  }

  function setup(kc: any) {
    const runtimeConfig = useRuntimeConfig()

    keycloak.value = kc

    const server =
      runtimeConfig.public.ENVIRONMENT_NAME === 'alpha'
        ? 'TrpServerTesting'
        : 'TrpServer'

    userProfile.value = {
      IsEmailVerified: kc.idTokenParsed?.email_verified,
      Name: kc.idTokenParsed?.name,
      UserName: kc.idTokenParsed?.preferred_username,
      FirstName: kc.idTokenParsed?.given_name,
      LastName: kc.idTokenParsed?.family_name,
      Email: kc.idTokenParsed?.email,
      UserId: kc.idTokenParsed?.trp_user_id,
      IsAdmin: kc.resourceAccess?.[server]?.roles.includes('Admin'),
      FieldsRecognition:
        kc.resourceAccess?.[server]?.roles.includes('fields-recognition'),
      SuperModels: kc.resourceAccess?.[server]?.roles.includes('super-models'),
      NER: kc.resourceAccess?.[server]?.roles.includes(
        'named-entity-recognition'
      ),
      ViewAllModels:
        kc.resourceAccess?.[server]?.roles.includes('view-all-models'),
      UpdateAllModels:
        kc.resourceAccess?.[server]?.roles.includes('update-all-models'),
      SmartSearch: kc.resourceAccess?.[server]?.roles.includes('smart-search'),
      TablesRecognition:
        kc.resourceAccess?.[server]?.roles.includes('tables-recognition'),
      CollaborationTools: kc.resourceAccess?.[server]?.roles.includes(
        'collaboration-tools'
      ),
      Export: kc.resourceAccess?.[server]?.roles.includes('export'),
      Scholar: kc.realmAccess?.roles?.includes('subscription-scholar'),
      Era: kc.realmAccess?.roles.includes('subscription-era'),
      Epoch: kc.realmAccess?.roles?.includes('subscription-epoch'),
      Eon: kc.realmAccess?.roles?.includes('subscription-eon'),
      Team: kc.realmAccess?.roles?.includes('subscription-team'),
      Individual:
        !kc.realmAccess?.roles?.includes('subscription-scholar') &&
        !kc.realmAccess?.roles.includes('subscription-era') &&
        !kc.realmAccess?.roles.includes('subscription-epoch') &&
        !kc.realmAccess?.roles?.includes('subscription-eon') &&
        !kc.realmAccess?.roles?.includes('subscription-team'),
    }
    if (kc.realmAccess?.roles) {
      roles.value = roles.value.concat(kc.realmAccess.roles)
    }

    if (kc.resourceAccess?.account?.roles) {
      roles.value = roles.value.concat(kc.resourceAccess?.account?.roles)
    }
  }

  function isSet() {
    return keycloak.value != null
  }

  return {
    keycloak,
    setup,
    isSet,
    userProfile,
    roles,
    hasRole,
    setLogined,
    loggedIn,
  }
})
