export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
      console.log("🚀 ~ defineNuxtPlugin ~ error:", error)
      if (error.response && error.response.status === 404) {
        // Handle 404 error here, e.g. redirect to a custom 404 page
        console.log('API returned a 404 error:', error)
      } else {
        // Handle other errors
      }
    }
  
    // Also possible
    nuxtApp.hook('vue:error', (error, instance, info) => {
      console.log("🚀 ~ nuxtApp.hook ~ error, instance, info:", error, instance, info)
      // TODO: maybe clear error here
      // handle error, e.g. report to a service
    })
  })
  