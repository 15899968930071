import PostHog from 'posthog-js'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  // if(config.public.name === 'alpha') return
  const posthog = PostHog.init(config.public.POSTHOG_API_KEY, {
    api_host: 'https://eu.i.posthog.com',
  })

  // Inject posthog to the context as $posthog
  nuxtApp.provide('posthog', posthog)
})
