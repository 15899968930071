export default defineNuxtPlugin(nuxtApp => {
  // TODO check if we can use the feature flag in posthog
  const config = useRuntimeConfig()

  const isAlpha = config.public.name === 'alpha'
  const isBeta = config.public.name === 'beta'
  const isApp = config.public.name === 'app'

  const features = {
    tableAdvancedSetting: isAlpha,
    scrollable: isAlpha,
    learn: isAlpha || isBeta,
    qc: isAlpha,
    trainingRuns: isAlpha,
    editorToggle: isAlpha || isBeta || isApp,
    comments: isAlpha,
    pageMetadata: isAlpha,
    uploadDemo: isAlpha || isBeta,
    singlePageEditor: isAlpha || isBeta || isApp,
    invitations: isAlpha,
    posthogTesting: isAlpha,
  }
  nuxtApp.provide('features', features)
})
